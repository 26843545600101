import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import Cookies from "js-cookie";
import { ShowSnackBar, LogoutProperty } from "./app";
import { LogoutTenant } from "./tenant";
import { LogoutApplication } from "./applications";
import { listingList, LogoutListing } from "./listing";

const initialState = {
  isLoggedIn: false,
  token: "",
  email: "",
  joint_applicants:[],
  userData: [],
  mobileNumber: "",
  user_id: "",
  error: false,
  isLoading: false,
  stepComplete: 0,
  user: [],
  business: "",
  role: "",
  business_id: "",
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateIsLoading(state, action) {
      state.error = action.payload.error;
      state.isLoading = action.payload.isLoading;
    },
    logIn(state, action) {
      state.isLoggedIn = action.payload.isLoggedIn;
      state.token = action.payload.token;
      state.email = action.payload.email;
      state.user_id = action.payload.user_id;
      state.role = action.payload.role;
      state.business_id = action.payload.business_id;
    },
    signOut(state, action) {
      state.isLoggedIn = false;
      state.isLoggedIn = "";
      state.email = "";
      state.token = "";
      state.stepComplete = 0;
      state.user_id = "";
      state.user = "";
      state.business = "";
      state.business_id = "";
    },
    updateRegisterEmail(state, action) {
      state.email = action.payload.email;
      state.mobileNumber = action.payload.mobileNumber;
      state.role = action.payload.role;
    },
    joint_applicants(state,action){
      state.joint_applicants= action.payload.joint_applicants
      console.log(state.joint_applicants,'payload')
    },
    userData(state, action) {
      state.userData = action.payload.userData
      console.log(state.userData, "state.userData")
    },
    updateStepsComplete(state, action) {
      state.stepComplete = action.payload.stepComplete;
    },
    fetchUser(state, action) {
     
      state.user = action.payload.user;
      state.business_id = action.payload.user.business_id;
    },
    fetchBusiness(state, action) {
      state.business = action.payload.business;
    },
  },
});
export default slice.reducer;
// get agent details...
export function getUser() {
  return async (dispatch, getState) => {
    await axios
      .get("/auth/get-user", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.fetchUser({ user: response.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
// get user details by id
// export function GetUserDetailsById(data) {
//   console.log(data, 'email in auth.js in Joint applicant')
//   return async (dispatch, getState) => {
//     dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));
//     await axios.post("/auth/get-user-by-id", { user_id: data.user_id },
//       { headers: { "Content-Type": "application/json" } }
//     )
//       .then(function (response) {
//         console.log(response.data, "joint app");
//         dispatch(slice.actions.unloggedUser({ unloggedUser: response.data.data }));
//         console.log(response.data.data, 'resss of joint applicants')
//         // dispatch(slice.actions.updateStepsComplete({ stepComplete: 1 }));
//         dispatch(ShowSnackBar({ severity: "success", message: response.data.message }));
//         dispatch(slice.actions.updateIsLoading({ isLoading: false, error: false }));
//       })
//       .catch((error) => {
//         dispatch(ShowSnackBar({ severity: "error", message: error.message || error.msg }));
//         dispatch(slice.actions.updateIsLoading({ error: true, isLoading: false }));
//       })
//   };
// }
// get agent details...
export function getBusinessDetails(user_id) {
  return async (dispatch, getState) => {
    await axios
      .get(`/auth/get-business/${user_id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
       
        dispatch(slice.actions.fetchBusiness({ business: response.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
// User log in
export function LoginUser(formValues) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        "/auth/login",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        dispatch(
          slice.actions.logIn({
            isLoggedIn: true,
            token: response.data.token,
            user_id: response.data.user_id,
            role: response.data.role,
          })
        );
        window.localStorage.setItem("user_id", response.data.user_id);
        // window.localStorage.setItem("token", response.data.token);

        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );

        if (response.status === 200) {
          const data = response.data;
          // const { title, body } = data;

          // Send the notification payload to the messaging service worker
          navigator.serviceWorker.ready.then((registration) => {
            registration.showNotification(data?.title, {
              body: data?.body,
            });
          });
        }
      })
      .catch((error) => {
        dispatch(ShowSnackBar({ severity: "error", message: error.message }));
        dispatch(
          slice.actions.updateIsLoading({ error: true, isLoading: false })
        );
      });
    // .finally(() => {
    //   if (!getState().auth.error) {
    //     const path =
    //       getState().auth.role === "agent" ? "/properties" : "/watchlist";
    //     window.location.href = path;
    //   }
    // });
  };
}
export function GoogleLoginUser(formValues, role) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));

    try {
      const response = await axios.post(
        `/auth/google-login/${role}`,
        formValues
      );
      dispatch(
        slice.actions.logIn({
          isLoggedIn: true,
          token: response.data.token,
          email: response.data.email,
          user_id: response.data.user_id,
        })
      );

      window.localStorage.setItem("user_id", response.data.user_id);
      dispatch(slice.actions.updateStepsComplete({ stepComplete: 2 }));
      dispatch(
        ShowSnackBar({ severity: "success", message: response.data.message })
      );
    } catch (error) {

      dispatch(ShowSnackBar({ severity: "error", message: error.message }));
      dispatch(
        slice.actions.updateIsLoading({ isLoading: false, error: true })
      );
      return;
    }

    // dispatch(slice.actions.updateIsLoading({ isLoading: false, error: false }));
    // window.location.href = "/auth/complete-profile";
  };
}

export function LogoutUser(navigate) {
  return async (dispatch, getState) => {

    let path = getState().auth?.user?.role === "agent" ? "/agent-login" : "/tenant-login";

    // let path = getState().auth?.user?.role === "agent" ? "/landing" : "/";

    try {
      // window.localStorage.removeItem("user_id")
      const preservedState = window.localStorage.getItem("State")

      localStorage.clear();
      Cookies.remove("session")
      if (preservedState) {
        window.localStorage.setItem("State", preservedState);
      }
      await Promise.all([
        dispatch(slice.actions.signOut()),
        dispatch(LogoutApplication()),
        dispatch(LogoutProperty()),
        dispatch(LogoutListing()),
        dispatch(LogoutTenant()),
        dispatch(slice.actions.updateIsLoading({ isLoading: false, error: false })),
        dispatch(ShowSnackBar({ severity: "success", message: "Logged out successfully!" }))
      ]).then(() => {
        if (!getState().auth?.error) {
          navigate(path)
          // window.location.href = path;
        }
      })
    } catch (error) {
      console.log(error);
    }

  };
}

export function ForgetPassword(formValues) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        "/auth/forgot-password",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
      })
      .catch((error) => {
        dispatch(ShowSnackBar({ severity: "error", message: error.message }));
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: true })
        );
      });
  };
}
export function NewPassword(formValues) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        "/auth/change-password",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(
          slice.actions.logIn({
            isLoggedIn: true,
            token: response.data.token,
            user_id: response.data.user_id,
          })
        );
        window.localStorage.setItem("user_id", response.data.user_id);
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
      })
      .catch((error) => {
        dispatch(ShowSnackBar({ severity: "error", message: error.message }));
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: true })
        );
      });
  };
}
export function ResetPassword(formValues, resetToken) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        "/auth/reset-password",
        {
          ...formValues,
          resetToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {

        // dispatch(
        //   slice.actions.logIn({
        //     isLoggedIn: true,
        //     token: response.data.token,
        //     user_id: response.data.user_id,
        //   })
        // );
        // window.localStorage.setItem("user_id", response.data.user_id);
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
        window.location.href = "/";
      })
      .catch((error) => {
        dispatch(ShowSnackBar({ severity: "error", message: error.message }));
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: true })
        );
      });
  };
}
export function ResendOtp(email) {
  return async (dispatch) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        "/auth/resendOtp",
        {
          email,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
      })
      .catch((error) => {
        dispatch(ShowSnackBar({ severity: "error", message: error.message }));
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: true })
        );
      });
  };
}
export function RegisterUser(formValues, navigate) {
  const path =
    formValues.role === "agent" ? "/agent/verify-otp" : "/tenant-verify-otp";
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        "/auth/register",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        dispatch(
          slice.actions.updateRegisterEmail({
            email: formValues.email,
            role: formValues.role,
            mobileNumber: `+61${formValues.mobileNumber}`,
          })
        );
        dispatch(slice.actions.updateStepsComplete({ stepComplete: 1 }));
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
      })
      .catch((error) => {
        dispatch(ShowSnackBar({ severity: "error", message: error.message || error.msg }));
        dispatch(
          slice.actions.updateIsLoading({ error: true, isLoading: false })
        );
      })
      .finally(() => {
        if (!getState().auth.error) {
          navigate(path);
          // window.location.href = path;
        }
      });
  };
}

export function GetUserByEmail({ email }) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));
    try {
      const response = await axios.post("/auth/get-user-by-email", { email },
        { headers: { "Content-Type": "application/json" } }
      )
      console.log(response.data, "joint app reponse");
      dispatch(slice.actions.userData({ userData: response.data.data }));
      dispatch(slice.actions.updateIsLoading({ isLoading: false, error: false }));
      return response
    } catch (error) {
      dispatch(ShowSnackBar({ severity: "error", message: error.message || error.msg }));
      dispatch(slice.actions.updateIsLoading({ error: true, isLoading: false }));
    }
  };
}


export function AddJointApplicant(data) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));

    // const emailRegexTest = (/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(data.email)
    // console.log(emailRegexTest, "REGEX EMAIL In AUTH");
    // if (!emailRegexTest) {
    //   return dispatch(ShowSnackBar({ severity: "error", message: "Enter valid Email Address" }));
    // }
console.log(data,"DATA")
    await axios.post("/auth/add-joint-applicant",
      {
        data
        // email: data.email,
        // user_id: data.user_id,
        // sendContract: data.sendContract || false
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then(function (response) {
        // dispatch(slice.actions.joint_applicants({joint_applicants:response.data.data}));
        dispatch(ShowSnackBar({ severity: "success", message: response.data.message }));
        dispatch(slice.actions.updateIsLoading({ isLoading: false, error: false }));
      })
      .catch((error) => {
        dispatch(ShowSnackBar({ severity: "error", message: error.message || error.msg }));
        dispatch(slice.actions.updateIsLoading({ error: true, isLoading: false }));
      })
  };
}

export function GetJointApplicant(data) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));
    await axios.post("/auth/get-joint-applicant", { user_id: data.user_id },
      { headers: { "Content-Type": "application/json" } }
    )
      .then(function (response) {
        console.log(response.data.data.joint_applicants,"jjjjjjjjjjj")
        dispatch(slice.actions.joint_applicants({ joint_applicants: response.data.data.joint_applicants }));
        response.data.data.joint_applicants.preApproved &&
        // dispatch(slice.actions.updateStepsComplete({ stepComplete: 1 }));
        dispatch(ShowSnackBar({ severity: "success", message: response.data.message }));
        dispatch(slice.actions.updateIsLoading({ isLoading: false, error: false }));
      })
      .catch((error) => {
        dispatch(ShowSnackBar({ severity: "error", message: error.message || error.msg }));
        dispatch(slice.actions.updateIsLoading({ error: true, isLoading: false }));
      })
  };
}

export function RemoveJointApplicant({user_id,email}) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));
    await axios.post("/auth/remove-joint-applicant",
        {email:email,
          user_id:user_id},
        {
          headers: {"Content-Type": "application/json"},
        }
      )
      .then(function (response) {console.log("remove joint applicant in then part")
        // dispatch(slice.actions.joint_applicant({joint_applicant:email}));
        // dispatch(slice.actions.updateStepsComplete({ stepComplete: 1 }));
        dispatch(ShowSnackBar({ severity: "success", message: response.data.message }));
        dispatch(slice.actions.updateIsLoading({ isLoading: false, error: false }));
      })
      .catch((error) => {
        dispatch(ShowSnackBar({ severity: "error", message: error.message || error.msg }));
        dispatch(slice.actions.updateIsLoading({ error: true, isLoading: false }));
      })
  };
}

export function UpdateJointApplicant(data) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));
    await axios.post("/auth/update-joint-applicant",
      data.reset ? { reset: data.reset, user_id: data.user_id } : { email: data.email, user_id: data.user_id, action: data.action },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then(function (response) {
        // dispatch(slice.actions.joint_applicant({joint_applicant:email}));
        // dispatch(slice.actions.updateStepsComplete({ stepComplete: 1 }));
        dispatch(ShowSnackBar({ severity: "success", message: response.data.message }));
        dispatch(slice.actions.updateIsLoading({ isLoading: false, error: false }));
      })
      .catch((error) => {
        dispatch(ShowSnackBar({ severity: "error", message: error.message || error.msg }));
        dispatch(slice.actions.updateIsLoading({ error: true, isLoading: false }));
      })
  };
}

export function AddBusiness(formValues, files) {
  const formData = {
    ...formValues,
    file: files[0],
  };

  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post("/auth/add-business", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(slice.actions.updateStepsComplete({ stepComplete: 4 }));
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
      })
      .catch((error) => {
        dispatch(ShowSnackBar({ severity: "error", message: error.message }));
        dispatch(
          slice.actions.updateIsLoading({ error: true, isLoading: false })
        );
      })
      .finally(() => {
        if (!getState().auth.error) {
          window.location.href = "/agent/invite";
        }
      });
  };
}
export function VerifyEmail(formValues) {
  const path =
    formValues.role === "agent" ? "/agent/complete-profile" : "/tenant/details";
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        "/auth/verify-otp",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        dispatch(
          slice.actions.logIn({
            isLoggedIn: true,
            token: response.data.token,
            user_id: response.data.user_id,
          })
        );
        dispatch(slice.actions.updateStepsComplete({ stepComplete: 2 }));
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
      })
      .catch((error) => {
        dispatch(ShowSnackBar({ severity: "error", message: error.message }));
        dispatch(
          slice.actions.updateIsLoading({ error: true, isLoading: false })
        );
      })
      .finally(() => {
        if (!getState().auth.error) {
          window.location.href = path;
        }
      });
  };
}
export function CompleteProfile(formValues, files) {
  const formData = {
    ...formValues,
    file: files[0],
  };
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        "/auth/complete-profile",

        formData,

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(
          slice.actions.fetchBusiness({
            business: response.data.businessDetails,
          })
        );
        dispatch(slice.actions.updateStepsComplete({ stepComplete: 3 }));
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
      })
      .catch((error) => {
        dispatch(ShowSnackBar({ severity: "error", message: error.message }));
        dispatch(
          slice.actions.updateIsLoading({ error: true, isLoading: false })
        );
      })
      .finally(() => {
        if (!getState().auth.error) {
          window.location.href = "/agent/business-details";
        }
      });
  };
}
export function EditProfile(formValues, files) {
  const formData = { ...formValues, file: files[0] };

  // formData.append("file", files);

  return async (dispatch, getState) => {
    await axios
      .post("/auth/edit-profile", formData, {
        headers: {
          "content-Type": "multipart/form-data",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then(function (response) {

        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
      })
      .catch(function (err) {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: true })
        );
      });
    // .finally(() => {
    //   if (!getState().auth.error) {
    //     window.location.href = "/agent/business-details";
    //   }
    // });
  };
}
export function EditBusinessDetails(formValues, files) {
  const formData = { ...formValues, file: files[0] };

  return async (dispatch, getState) => {
    await axios
      .post("/auth/edit-business-details", formData, {
        headers: {
          "content-Type": "multipart/form-data",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then(function (response) {

        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
      })
      .catch(function (err) {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: true })
        );
      });
    // .finally(() => {
    //   if (!getState().auth.error) {
    //     window.location.href = "/agent/business-details";
    //   }
    // });
  };
}
export function EditPosition(formValues) {
  const formData = { ...formValues };

  return async (dispatch, getState) => {
    await axios
      .post("/auth/edit-profile", formData, {
        headers: {
          "content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {

        // dispatch(
        //   ShowSnackBar({ severity: "success", message: response.data.message })
        // );
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
      })
      .catch(function (err) {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: true })
        );
      });
  };
}
export function InviteTeam(formValues, user_id, setRefresh) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        "/auth/invite",
        {
          formValues,
          user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(slice.actions.updateStepsComplete({ stepComplete: 5 }));
        dispatch(
          slice.actions.updateIsLoading({ isLoading: false, error: false })
        );
        setRefresh(true);
      })
      .catch((error) => {
        dispatch(ShowSnackBar({ severity: "error", message: error.message }));
        dispatch(
          slice.actions.updateIsLoading({ error: true, isLoading: false })
        );
      })
      .finally(() => {
        if (!getState().auth.error && window.location.pathname != "/setting") {
          window.location.href = "/agent/select-plan";
        }
      });
  };
}
export function SendNotification(data) {
  return async (dispatch, state) => {
    try {
      const res = await axios.post("/auth/send-notification", data);
      if (res.status === 200) {
        const data = res.data;
        const { title, body, link } = data.message.notification;


        // navigator.serviceWorker.ready.then((registration) => {
        //   registration.showNotification(title, {
        //     body: body,
        //     link: "http://localhost:3000/setting"
        //   });

        //   registration.addEventListener('notificationclick', (event) => {
        //     event.notification.close();
        //     event.waitUntil(
        //       window.openWindow(event.notification.data.link)
        //     );
        //   });
        // });

        dispatch(
          ShowSnackBar({
            severity: "success",
            message: "Notification recieved",
          })
        );
      }
    } catch (err) {
      dispatch(ShowSnackBar({ severity: "error", message: err.message }));
      dispatch(slice.actions.updateIsLoading({ err: true, isLoading: false }));
    }
  };
}
export const { updateIsLoading } = slice.actions;
