import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { updateIsLoading } from "../slices/auth";
import { ShowSnackBar, allPropertyList } from "./app";

const initialState = {
  create: {
    tenantData: null,
    listing: null,
    landlordInfo_id: null,
    termInfo_id: null,
    additionalInfo_id: null,
    // application_id:null,
    applicationData:null
  },
  get: {
    termInfo: null,
    landlordInfo: null,
    
  },
  applicationsByAgent: [],
  appPropertyAddress: [],
  matchedProperties: [],
  preApproveInfo: {},
  TenantModalData: {},
  tenantSignature: "",
  tenantMatchedProperty: [],
  tenantAllDoc: [],
  signNowAccessToken: "",
  leaseDoc: {},
  redirect_to_url: "",
  token: "",
  tenantSignatureNumber:0,
  tenant_signature_Link: "",
  additionalInfo: null,
};

const slice = createSlice({
  name: "application",
  initialState,
  reducers: {
    selectListing(state, action) {
      state.create.tenantData = action.payload.tenantData;
      state.create.listing = action.payload.listing;
    },
    addLandlordInfoContract(state, action) {
      if (action.payload.landlordInfo_id) {
        state.create.landlordInfo_id = action.payload.landlordInfo_id;
      } else {
        state.get.landlordInfo = action.payload.info;
      }
    },
    addTermInfo(state, action) {
      if (action.payload.termInfo_id) {
        state.create.termInfo_id = action.payload.termInfo_id;
        state.get.termInfo = action.payload.termInfo;
      } else {
        state.get.termInfo = action.payload.info;
      }
    },
    addAdditionalInfo(state, action) {
      state.create.additionalInfo_id = action.payload.additionalInfo_id;
      if (action.payload.additionalInfo_id) {
        state.create.additionalInfo_id = action.payload.additionalInfo_id;
        state.additionalInfo = action.payload.additionalInfo;
      } else {
        state.additionalInfo = action.payload.info;
      }
    },
    // application_id(state,action){
    //   state.create.application_id = action.payload.aplication_id;
    //   console.log(state.create.application_id,"state.create.applicationId")
    // },
    applicationData(state,action){
      state.create.applicationData = action.payload.applicationData;
      console.log(state.create.applicationData,"state.create.applicationData")
    },
    setLandlordId(state, action) {
      state.create.landlordInfo_id = action.payload.landlordInfo_id;
    },
    setTenantAllDoc(state, action) {
      state.tenantAllDoc = action.payload.result;
    },
    setTenantSignutare(state, action) {
      state.tenantSignature = action.payload.Info;
    },

    setSignNowAccessToekn(state, action) {
      state.signNowAccessToken = action.payload.Info;
    },
    applicationByAgent(state, action) {
      state.applicationsByAgent = action.payload.list;
    },
    setAppPropertyAddress(state, action) {
      state.appPropertyAddress = action.payload.appPropertyAddress;
    },
    setTenantMatchedProperty(state, action) {
      state.tenantMatchedProperty = action.payload.data;
    },
    matchedProperties(state, action) {
      state.matchedProperties = action.payload.list;
    },
    getPreApproveInfo(state, action) {
      state.preApproveInfo = action.payload.preApproveInfo;
    },
    setLeaseDoc(state, action) {
      state.leaseDoc = action.payload.signId;
    },
    setUrl(state, action) {
      state.redirect_to_url = action.payload.url;
    },
    tenantSignatureNumber(state,action){
      state.tenantSignatureNumber = action.payload.tenantSignature
      console.log(state.tenantSignatureNumber,"tenantSignatureNumber in application.js")
    },
    setTenant_signature_Link(state, action) {
      state.tenant_signature_Link = action.payload.tenant_signature_Link
      console.log(state.tenant_signature_Link,"tenant_signature_link in application.js")
    },
    setToken(state, action) {
      state.token = action.payload.token;
    },
    setTenantModalData(state, action) {
      state.TenantModalData = action.payload.TenantModalData;
    },
    signOutApplication(state, action) {
      state.create.tenantData = null;
      state.create.listing = null;
      state.create.landlordInfo_id = null;
      state.create.additionalInfo_id = null;
      // state.create.application_id = null;
      state.create.applicationData = null;
      state.create.termInfo_id = null;
      state.get.landlordInfo = null;
      state.get.termInfo = null;
      // state.additionalInfo = null;
      state.applicationsByAgent = [];
      state.matchedProperties = [];
      state.preApproveInfo = [];
      state.tenantMatchedProperty = [];
      state.tenantSignature = "";
      state.TenantModalData = null;
    },
  },
});
export default slice.reducer;

export function LogoutApplication() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.signOutApplication());
  };
}
export function AddLandLordInfoFuncContract(formValues, id, navigate) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        "/application/add_landlord_info",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then(function (response) {
        dispatch(
          slice.actions.addLandlordInfoContract({
            landlordInfo_id: response.data.landlordInfo_id,
            listing_id: response.data.listing_id,
          })
        );
        // dispatch(AddAddress({ address_id: response.data.id }));
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch(function (err) {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      })
      .finally(() => {
        if (!getState().auth.error) {
          navigate(`/Add-TermInfo?id=${id}`);
        }
      });
  };
}
export function AddTermInfoFunc(formValues, navigate) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        "/application/add_term_info",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then(function (response) {
        dispatch(
          slice.actions.addTermInfo({
            termInfo_id: response.data.termInfo_id,
            termInfo: response.data.termInfo,
          })
        );
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch(function (err) {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      })
      .finally(() => {
        if (!getState().auth.error) {
          navigate(`/Additional_Info?id=${formValues?.id}`);
        }
      });
  };
}
export function AddAdditionalInfoFunc(formValues, navigate) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        "/application/add_additional_info",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) { console.log(response,"response")
        dispatch(
          slice.actions.addAdditionalInfo({
            additionalInfo_id: response?.data?.additionalInfo_id,
            additionalInfo: response.data.addtionalInfo,
          })
        );
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch(function (err) {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      })
      .finally(() => {
        if (!getState().auth.error) {
          navigate(`/Send_contractReview?id=${formValues?.id}`);
          // navigate(`/review_documents?id=${formValues?.id}`);
        }
      });
  };
}

export function GetSignNowDoc(id) {
  return async (dispatch, getState) => {

    await axios
      .get(
        `/application/get_signnow_doc/${id}`,

        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(
          slice.actions.setTenantAllDoc({ result: response?.data?.result })
        );
        // dispatch(
        //   slice.actions.setLandlordId({ landlordInfo_id: response?.data?._id })
        // );
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
      });
  };
}

export function getDoc() {
  return async (dispatch, getState) => {
    try {
      await axios
        .get("/application/get_selected_doc", {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          dispatch(
            slice.actions.setSignNowAccessToekn({
              Info: response.data.data.access_token,
            })
          );
        })
        .catch((err) => {
          dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        });
    } catch (err) {
      console.error(err);
      dispatch(ShowSnackBar({ severity: "error", message: err.message }));
    }
  };
}

export function GetLandlordInfo(id) {
  return async (dispatch, getState) => {
    await axios
      .get(
        `/application/get_landlord_info/${id}/`,

        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(
          slice.actions.addLandlordInfoContract({ info: response?.data })
        );
        dispatch(
          slice.actions.setLandlordId({ landlordInfo_id: response?.data?._id })
        );
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
      });
  };
}
export function GetTermInfo(id) {
  return async (dispatch, getState) => {
    await axios
      .get(`/application/get_term_info/${id}`, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.addTermInfo({ info: response.data }));
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
      });
  };
}
export function GetAdditionalInfo(id) {
  return async (dispatch, getState) => {
    await axios
      .get(`/application/get_additional_info/${id}`, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        dispatch(
          slice.actions.addAdditionalInfo({
            info: response.data.additionalInfo,
          })
        );
        dispatch(slice.actions.addTermInfo({ info: response.data.termInfo }));
        dispatch(
          slice.actions.addLandlordInfoContract({
            info: response.data.landlordInfo,
          })
        );
        dispatch(
          slice.actions.setTenantSignutare({ Info: response.data.Info })
        );
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
      });
  };
}
export function CreateApplication(data, id, navigate, role) {
  return async (dispatch, getState) => {console.log('DATA',data)
    await axios
      .post(
        "/application/create",
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },  
        }
      )
      .then((response) => {console.log(response,"RRRRR");
        
        // dispatch(slice.actions.application_id({application_id:response.data.data._id}))
        dispatch(slice.actions.applicationData({applicationData: response.data.data}))
        
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
        if (response?.data?.statusCode === 1) {
          dispatch(slice.actions.addLandlordInfoContract({ info: null }));
          dispatch(slice.actions.setLandlordId({ landlordInfo_id: null }));
        }
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      })
      .finally(() => {
        // if (role === "tenant" ) {
        //   navigate(`/Congratulation`);
        // } else if (window.location.pathname === `/edit_leaseDoc`) {
        //   return;
        // }
        // else if (!getState().auth.error) {
        //   window.location.href = `/applications`;
        // }
      });
  };
}

// export function AddJointApplicantToApplication(data) {
//   return async (dispatch, getState) => {

//     // const emailRegexTest = (/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(data.email)
//     // console.log(emailRegexTest,"REGEX EMAIL In APP");
//     // if(!emailRegexTest){
//     //   return dispatch(ShowSnackBar({ severity: "error", message: "Enter valid Email Address" }));
//     // }
//     await axios.post("/application/add_joint_applicant_to_application",
//         {applicationId:data.applicationId,
//           email:data.email
//         },
//         {
//           headers: {"Content-Type": "application/json"},
//         }
//       )
//       .then(function (response) {console.log(response,"response of application")
//         dispatch(ShowSnackBar({ severity: "success", message: response.data.message }));
//         dispatch(slice.actions.updateIsLoading({ isLoading: false, error: false }));
//       })
//       .catch((error) => {
//         dispatch(ShowSnackBar({ severity: "error", message: error.message || error.msg }));
//         dispatch(slice.actions.updateIsLoading({ error: true, isLoading: false }));
//       })
//   };
// }

export function GetApplication({id}) {
  return async (dispatch, getState) => {
    // dispatch(slice.actions.updateIsLoading({ isLoading: true, error: false }));
    try {
      const response =await axios.post("/application/get_application_info",{id},
          {headers: {"Content-Type": "application/json"}}
        )
          await dispatch(slice.actions.tenantSignatureNumber({tenantSignature: response.data.data.tenantSignature}));
          // dispatch(slice.actions.updateStepsComplete({ stepComplete: 1 }));
          dispatch(ShowSnackBar({ severity: "success", message: response.data.message }));
          // dispatch(slice.actions.updateIsLoading({ isLoading: false, error: false }));
        
    } catch(error) {
        dispatch(ShowSnackBar({ severity: "error", message: error.message || error.msg })); 
        // dispatch(slice.actions.updateIsLoading({ error: true, isLoading: false }));
      }
  };
}


export function ApplicationsByAgentId(id) {
  return async (dispatch, getState) => {
    await axios
      .get(
        `/application/get_applications_by_agent/${id.user_id}?pgNum=${id?.pgNum}&pgSize=${id?.pgSize}`,
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(
          slice.actions.applicationByAgent({
            list: response?.data?.allApplications ?? [],
          })
        );
        dispatch(
          allPropertyList({
            pageNumber: response.data.pageNumber,
            pageSize: response.data.pageSize,
            totalProperties: response.data.totalProperties,
            noOfPropertiesPerPage: response.data.noOfPropertiesPerPage,
          })
        );
      })
      .catch((err) => {
        console.log(err.message, "check it")
        // dispatch(ShowSnackBar({ severity: "error", message: err.message }));
      });
  };
}
export function GetMatchedProperties(data) {
  return async (dispatch, getState) => {
    await axios
      .get(
        `/application/get_matched_properties`,
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(
          slice.actions.matchedProperties({ list: response.data.allProperties })
        );
      })
      .catch((err) => {
        console.log(err.message, "check it")
        // dispatch(ShowSnackBar({ severity: "error", message: err.message }));
      });
  };
}
export function GetPreApprovedInfo(id) {
  return async (dispatch, getState) => {
    await axios
      .post(
        `/application/get_pre_approved_informantion`,
        {
          id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(
          slice.actions.getPreApproveInfo({
            preApproveInfo: response.data.preApprovalInfo,
          })
        );
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
      });
  };
}

export function getTenantModalDetail(id) {
  return async (dispatch, getState) => {
    await axios
      .get(`/application/get_tenant_modal_data/${id}`, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        dispatch(
          slice.actions.setTenantModalData({
            TenantModalData: response.data.TenantModalData,
          })
        );
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
      });
  };
}
export function getTenantMatchedPropery(_id) {
  return async (dispatch, getState) => {
    await axios
      .get(`/application/get_tenant_matchedProperty/${_id}`, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        dispatch(
          slice.actions.setTenantMatchedProperty({ data: response.data.data })
        );
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
      });
  };
}

export function uploadDoc(files, userId, application, navigate) {
  return async (dispatch, getState) => {
    const formData = new FormData();
    if (files) {
      formData.append("files", files[0]);
      formData.append("id", userId);
      formData.append("application", application);
    }
    await axios
      .post(`/application/uploadDoc`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          // Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setLeaseDoc({ signId: response.data.signId }));
        dispatch(slice.actions.setUrl({ url: response.data.url }))
        dispatch(slice.actions.setToken({ token: response.data.token }))
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
      })
      .finally(() => {
        if (!getState().auth.error) {
          navigate(`/edit_leaseDoc?id=${application}`);
          // navigate(`/review_documents?id=${formValues?.id}`);
        }
      });
  };
}

export function uploadEditedDoc(leaseDoc, token, firstName, lastName, email,joint_applicants,application_id) {
  return async (dispatch, getState) => { console.log(dispatch,"dispatch^^^",application_id)
    try {console.log("after dispatch in try",joint_applicants,"appli id",application_id)
      const response = await axios
        .post(`/application/uploadEditedDoc`, { leaseDoc, token, firstName, lastName, email,joint_applicants, application_id }, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        console.log(response.data,"Response of upload edited doc")
        if (response.data.tenant_signature_Link) {
          dispatch(slice.actions.setTenant_signature_Link({ tenant_signature_Link: response.data.tenant_signature_Link }));
        }
        
        return response.data;
        
    } catch (error) {
        dispatch(ShowSnackBar({ severity: "error", message: error.message }));
      }
  };
}



export function downloadFile(id) {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(`/application/download/${id}`, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${getState().auth.token}`,
        },
        responseType: 'blob', // Set the responseType to 'blob' to handle binary data
      });

      // Access the binary data directly from response.data
      const blobData = response.data;

      // Now you can do something with the blobData, for example, create a download link
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blobData);
      downloadLink.download = 'Contact.pdf'; // Replace with the desired file name

      // Append the link to the document, trigger a click, and then remove the link
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      dispatch(ShowSnackBar({ severity: "error", message: error.message }));
    }
  };
}

export const { selectListing } = slice.actions;
