import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import Message from "../../../Images/message.png";
import WhiteMainLogo from "../../../Images/WhiteMainLogo.png";
import { Link } from "react-router-dom";
import "../../agent/common.css";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../../../redux/slices/auth";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const { sideStepCount } = useSelector((state) => state.tenant);
  const { user } = useSelector((state) => state.auth);
  const { tenantOnboardingStatus } = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeClass, setActiveClass] = useState({
    pre: false,
    applicationPref: false,
    searchPref: false,
    notification: false,
  });

  const handleForms = () => {
    if (user && tenantOnboardingStatus) {
      const { preApproval, searchPrefrence, notification } = tenantOnboardingStatus;
  
      const newState = {};
      Object.keys(activeClass).forEach((key) => {
        switch (key) {
          case "pre":
            newState[key] = !Object.values(preApproval).includes(false);
            break;
            case "applicationPref":
              newState[key] = !Object.values(searchPrefrence).includes(false);
              break;
          case "searchPref":
            newState[key] = !Object.values(searchPrefrence).includes(false);
            break;
          case "notification":
            newState[key] = notification;
            break;
          default:
            newState[key] = activeClass[key];
            break;
        }
      });
  
      setActiveClass(newState);
    }
  };

  useEffect(() => {
    handleForms();
  }, [tenantOnboardingStatus]);

  useEffect(() => {
    dispatch(getUser());
  }, []);

  return (
    <div className="onboardStep ten_en">
      <div className="TenantreazyTop">
        <img
          src={WhiteMainLogo}
          alt=""
          onClick={() => navigate("/")}
          style={{
            cursor: "pointer",
          }}
        />
      </div>

      <div className="BoardingSteps">
        <div className="Agent_account_Data">
          <ul id="progressbar" className="text-center activedot">
            {/* <li className="active step"> */}
            <li
              className={
                activeClass.pre ? "active step0" : "activedot active step0"
              }
            >
              {/* <li className='step0'> */}

              <Link to="/tenant/details">
                <div className="OpenAccount">
                  <h4>Pre-Approval</h4>
                  <h6>Let’s get you pre-approved</h6>
                </div>
              </Link>
            </li>

          


            {/* <li className={user?.onBoardingSteps >= 4 ? "active step0" : "activedot active step0"}> */}
            <li
              className={
                activeClass.searchPref
                  ? "active step0"
                  : "activedot active step0"
              }
            >
              {/* <li className={user?.onBoardingSteps >= 3 ? "activedot active step0" : user?.onBoardingSteps >= 3 ? "active step0" : " step0"}> */}
              <Link to="/tenant/application-preferences">
                <div className="OpenAccount">
                  <h4>Application Preferences</h4>
                  <h6>Are you making a joint application? <br />Or going solo?</h6>
                </div>
              </Link>
            </li>
           
            <li
              className={
                activeClass.searchPref
                  ? "active step0"
                  : "activedot active step0"
              }
            >
              {/* <li className={user?.onBoardingSteps >= 3 ? "activedot active step0" : user?.onBoardingSteps >= 3 ? "active step0" : " step0"}> */}
              <Link to="/tenant/search-preference">
                <div className="OpenAccount">
                  <h4>Search Preferences</h4>
                  <h6>Let us know what you’re looking for</h6>
                </div>
              </Link>
            </li>

            {/* <li className={user?.onBoardingSteps >= 5 ? "active step0" : "activedot active step0"}> */}
            <li
              className={
                activeClass.notification
                  ? "active step0"
                  : "activedot active step0"
              }
            >
              {/* <li className={user?.onBoardingSteps >= 4 ? "activedot active step0" : user?.onBoardingSteps >= 4 ? "active step0" : " step0"}> */}
              <Link to="/tenant/notification-setting">
                <div className="OpenAccount">
                  <h4>Notifications</h4>
                  <h6>How would you like to be notified?</h6>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="onboardfoter">
        <h6>© Reazy 2022</h6>
        <div className="support">
          <img src={Message} alt="" />
          <h6>
            <a href="mailto:support@reazy.com.au">support@reazy.com.au</a>
          </h6>
          {/* <h6 style={{cursor:"pointer"}}>support@reazy.com.au</h6> */}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
