import { Suspense, lazy, useEffect } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import DashboardLayout from "../layouts/dashboard";
import { DEFAULT_PATH } from "../config";
import LoadingScreen from "../utils/Loader/Loading";
import AgentLayout from "../layouts/agent";
import TenantLayout from "../layouts/tenant";
import DefaultPath from "./DefaultPath";
import Terms from "../pages/tenant/Terms.js";


const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};
export default function Router() {
  const location = useLocation();
  const domain = window.location.host;


  let domains = {
    // for local
    //  tenantRoute: "localhost:5000",
    //   agentRoute: "localhost:5000",

    // // // for live server
    // tenantRoute: "stage-app.reazy.com.au",
    // agentRoute: "stage-client.reazy.com.au",

    // new
    tenantRoute: "dev-client.reazy.com.au",
    agentRoute: "dev.reazy.com.au",
  };

  useEffect(() => {
    document.title =
      // domain === "stage-app.reazy.com.au"
      domain === "dev-client.reazy.com.au"
        ? "Reazy - Tenant Portal"
        : "Reazy - Agent Portal";
  }, []);

  // useEffect(() => {
  //   const apiKey = "AIzaSyDYs1iyN-46vsict2HQEmDcr9y7RiCOCl4";
  //   const scriptUrl = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;

  //   axios
  //     .get(scriptUrl)
  //     .then(() => {})
  //     .catch((error) => {  2750642172
  //       console.error("Error loading Google Maps API:", error);
  //     });
  // }, []);

  const routes = [
    {
      path: "/",
      element: <Landing />,
    },
    {
      domain: domains.agentRoute,
      path: "/landing",
      element: <Landing />,
    },
    {
      domain: domains.agentRoute,
      path: "/contact-us",
      element: <Contact />,
    },
    {
      domain: domains.agentRoute,
      path: "/FAQs",
      element: <FAQs />,
    },
    {
      domain: domains.agentRoute,
      path: "/agent-login",
      element: <LoginPage />,
    },
    {
      domain: domains.agentRoute,
      path: "/about",
      element: <About />,
    },
    {
      domain: domains.tenantRoute,
      path: "/tenant-register",
      element: <TenantRegister />,
    },
    {
      domain: domains.agentRoute,
      path: "/terms",
      element: <Terms />,
    },
    // landing page route
    {
      domain: domains.tenantRoute,
      path: "/",
      element: <Landing />,
    },
    {
      domain: domains.tenantRoute,
      path: "/landing",
      element: <Landing />,
    },
    // {
    //   domain: domains.tenantRoute,
    //   path: "/",
    //   element: <TenantLogin />,
    // },
    {
      domain: domains.tenantRoute,
      path: "/tenant-login",
      element: <TenantLogin />,
    },
    {
      domain: domains.tenantRoute,
      path: "/contact-us",
      element: <Contact />,
    },
    {
      domain: domains.tenantRoute,
      path: "/about",
      element: <About />,
    },
    {
      domain: domains.tenantRoute,
      path: "/FAQs",
      element: <FAQs />,
    },
    {
      domain: domains.tenantRoute,
      path: "/tenant-verify-otp",
      element: <VerifyOtp />,
    },
    { domain: domains.agentRoute, 
      path: "redirecting", 
      element: <RedirectingPage />
    },

    {
      domain: domains.agentRoute,
      path: "/agent",
      element: <AgentLayout />,
      children: [
        { path: "register/:id?/:email?", element: <RegisterPage /> },
        { path: "verify-otp", element: <VerifyOTP /> },
        { path: "complete-profile", element: <CompleteProfile /> },
        { path: "invite", element: <InviteTeam /> },
        { path: "business-details", element: <BusinessDetail /> },
        { path: "select-plan", element: <SelectPlan /> },
      ],
    },
    {
      domain: domains.agentRoute,
      path: "/",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to={DEFAULT_PATH} replace />, index: true },
        { path: "app", element: <DefaultPath /> },
        // property routes
        { path: "properties", element: <CreateProperty /> },
        { path: "create_manual", element: <CreateManual /> },
        { path: "forgot", element: <ForgotPassword /> },
        { path: "update_password/:resetToken", element: <UpdatePassword /> },
        { path: "add_address", element: <AddAddress /> },
        { path: "property-information", element: <PropertyInformation /> },
        { path: "property-info-second", element: <PropertyInfoSecond /> },
        { path: "property-media", element: <PropertyMedia /> },
        { path: "review-property", element: <ReviewProperty /> },
        { path: "edit_leaseDoc", element: <LeaseDoc /> },
        { path: "agent_sign", element: <LeaseDoc /> },
        { path: "review_leaseDoc", element: <LeaseDoc /> },
        { path: "contract", element: <Contract /> },
        //All-Tenant for agent
        { path: "allTenant", element: <AllTenant /> },

        //listing routes
        { path: "listings", element: <ListingHome /> },
        { path: "select_property", element: <SelectProperty /> },
        { path: "add_landlord_info", element: <AddLandlordInfo /> },
        { path: "add_inspections", element: <AddInspections /> },
        { path: "review_listing", element: <ReviewListing /> },
        { path: "listing_details", element: <ListingDetails /> },
        { path: "Send_contract", element: <SendContract /> },
        { path: "aggrement_complete", element: <AggrementComplete /> },
        {
          path: "contract/add_landlord_info",
          element: <AddLandlordInfoContract />,
        },
        { path: "Congratulation", element: <CompletedApplication /> },
        {path :"CompleteApplication",element:<CompleteApplication/>},
        { path: "Send_contractReview", element: <SendContractReview /> },
        { path: "Add-TermInfo", element: <AddTermInfo /> },
        { path: "Additional_Info", element: <AdditionalInfo /> },
        { path: "Setting_Modal", element: <SettingModal /> },
        { path: "EditPayment_Modal", element: <EditPaymentModal /> },
        { path: "Application_Modal", element: <ApplicationModal /> },
        { path: "applications", element: <ApplicationAgentHome /> },
        { path: "review-application", element: <ReviewApplication /> },

        // setting routes
        { path: "setting", element: <Setting /> },
        // { path: "watchlist", element: <Watchlist /> },
        // { path: "view", element: <ViewProperty /> },
        // { path: "browse", element: <BrowseProperties /> },
        { path: "SignAgreement", element: <SignAgreement /> },
        { path: "review_documents", element: <ReviewDocuments /> },
        // 404
        { path: "404", element: <Page404 /> },
        // { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },

    {
      domain: domains.tenantRoute,
      path: "/tenant",
      element: <TenantLayout />,
      children: [
        { path: "details", element: <Details /> },
        { path: "current-landlord", element: <Current /> },
        { path: "id-document", element: <Document /> },
        { path: "address-history", element: <AddressHistory /> },
        // { path: "add-living-arrangement", element: <AddLivingArrangement /> },
        // { path: "connect-bank", element: <ConnectBank /> },
        // { path: "applications", element: <Applications /> },
        // {
        //   path: "addliving-arrangementform",
        //   element: <AddLivingArrangementForm />,
        // },
        { path: "application-preferences", element: <ApplicationPreferences /> },
        { path: "search-preference", element: <SearchPreference /> },
        { path: "rental-status", element: <RentalStatus /> },
        { path: "notification-setting", element: <NotificationSetting /> },
        {path:"bank-statement",element:<BankStatement/>},
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },

    {
      domain: domains.tenantRoute,
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "setting", element: <Setting /> },
        { path: "watchlist", element: <Watchlist /> },
        { path: "view", element: <ViewProperty /> },
        { path: "browse", element: <BrowseProperties /> },
        { path: "SignAgreement", element: <SignAgreement /> },
        { path: "review_documents", element: <ReviewDocuments /> },
        { path: "review_Uploaded_documents", element: <ReviewIfrmaeDoc /> },
        { path: "forgot", element: <ForgotPassword /> },
        { path: "update_password/:resetToken", element: <UpdatePassword /> },
        { path: "404", element: <Page404 /> },
        { path: "review-application", element: <ReviewApplication /> },
        { path: "applications", element: <ApplicationAgentHome /> },
        { path: "*", element: <Navigate to="/404" replace /> },
        { path: "applications", element: <ApplicationAgentHome /> },
        { path: "Send_contractReview", element: <SendContractReview /> },
        { path: "Congratulation", element: <CompletedApplication /> },
        {path :"CompleteApplication",element:<CompleteApplication/>}
      ],
    },

    { path: "*", element: <Navigate to="/402" replace /> },
  ];

  const filteredRoutes = routes.filter((route) => route.domain === domain);
  const routing = useRoutes(
    filteredRoutes.map((route) => ({
      ...route,
      path: route.path.replace("/", ""), // Remove leading slash from the path
    })),
    location.pathname
    // window.location.host
  );
  return routing;
}

const Contact = Loadable(lazy(() => import("../pages/tenant/Contact")));
const About = Loadable(lazy(() => import("../pages/tenant/About")));
const FAQs = Loadable(lazy(() => import("../pages/tenant/FAQ")));

const LoginPage = Loadable(
  lazy(() => import("../pages/agent/Onboarding/Login/Login"))
);
const RegisterPage = Loadable(
  lazy(() => import("../pages/agent/Onboarding/Register/Register"))
);
const VerifyOTP = Loadable(
  lazy(() => import("../pages/agent/Onboarding/Verify/Verify"))
);
const ForgotPassword = Loadable(
  lazy(() => import("../pages/agent/Onboarding/ForgotPassword/ForgotPassword"))
);
const UpdatePassword = Loadable(
  lazy(() => import("../pages/agent/Onboarding/UpdatePassword/UpdatePassword"))
);
const CompleteProfile = Loadable(
  lazy(() =>
    import("../pages/agent/Onboarding/CompleteProfile/CompleteProfile")
  )
);
const InviteTeam = Loadable(
  lazy(() => import("../pages/agent/Onboarding/InviteTeam/InviteTeam"))
);
const SelectPlan = Loadable(
  lazy(() => import("../pages/agent/Onboarding/SelectPlan/SelectPlan"))
);
const BusinessDetail = Loadable(
  lazy(() => import("../pages/agent/Onboarding/BusinessDetail/BusinessDetail"))
);
const CreateProperty = Loadable(
  lazy(() =>
    import("../pages/agent/CreateProperty/EmptyProperty/EmptyProperty")
  )
);
const CreateManual = Loadable(
  lazy(() => import("../pages/agent/CreateProperty/CreateManual/CreateManual"))
);
const AddAddress = Loadable(
  lazy(() => import("../pages/agent/CreateProperty/AddAddress/AddAddress"))
);
const PropertyInformation = Loadable(
  lazy(() =>
    import(
      "../pages/agent/CreateProperty/PropertyInformation/PropertyInformation"
    )
  )
);
const PropertyInfoSecond = Loadable(
  lazy(() =>
    import(
      "../pages/agent/CreateProperty/PropertyInfoSecond/PropertyInfoSecond"
    )
  )
);
const PropertyMedia = Loadable(
  lazy(() =>
    import("../pages/agent/CreateProperty/PropertyMedia/PropertyMedia")
  )
);
const ReviewProperty = Loadable(
  lazy(() =>
    import("../pages/agent/CreateProperty/ReviewProperty/ReviewProperty")
  )
);
const ReviewDocuments = Loadable(
  lazy(() =>
    import("../pages/agent/CreateProperty/ReviewDocuments/ReviewDocuments")
  )
);

const ReviewIfrmaeDoc = Loadable(
  lazy(() =>
    import("../pages/agent/CreateProperty/ReviewDocuments/ReviewIframeDoc")
  )
);

//----------All Tenant for agent--------//
const AllTenant = Loadable(
  lazy(() => import("../pages/agent/Tenant/AllTenant"))
);
// ---------------- listing pages --------------------------------
const ListingHome = Loadable(
  lazy(() => import("../pages/agent/Listings/Home/Home"))
);
const SelectProperty = Loadable(
  lazy(() => import("../pages/agent/Listings/SelectProperty/SelectProperty"))
);
const AddLandlordInfo = Loadable(
  lazy(() => import("../pages/agent/Listings/LandlordInfo/LandlordInfo"))
);
const AddLandlordInfoContract = Loadable(
  lazy(() =>
    import("../pages/agent/Applications/AddLandLordInfo/LandLordInfoContract")
  )
);
const AddInspections = Loadable(
  lazy(() => import("../pages/agent/Listings/Inspections/Inspections"))
);
const ReviewListing = Loadable(
  lazy(() => import("../pages/agent/Listings/Review/Review"))
);
const ListingDetails = Loadable(
  lazy(() => import("../pages/agent/Listings/DetailsView/DetailsView"))
);
const SendContract = Loadable(
  lazy(() => import("../pages/agent/Listings/SendContract/SendContract"))
);
const AggrementComplete  = Loadable(
  lazy(() => import("../pages/tenant/OnBoarding/SignAgreement/AggrementComplete.js"))
);
const CompletedApplication  = Loadable(
  lazy(() => import("../pages/tenant/Applications/completedApplication/completedApplication.js"))
);
const SendContractReview = Loadable(
  lazy(() =>
    import("../pages/agent/Listings/SendContractReview/SendContractReview")
  )
);
const ApplicationAgentHome = Loadable(
  lazy(() => import("../pages/agent/Applications/Home/Home"))
);
const LeaseDoc = Loadable(
  lazy(() => import("../pages/agent/Applications/LeaseDoc"))
);
const Contract = Loadable(
  lazy(() => import("../pages/agent/Applications/Contract"))
);
const CompleteApplication = Loadable(
  lazy(() => import("../pages/agent/Applications/CompleteApplication"))
);
const AdditionalInfo = Loadable(
  lazy(() => import("../pages/agent/Listings/AdditionalInfo/AdditionalInfo"))
);
const SettingModal = Loadable(
  lazy(() => import("../pages/agent/modal/SettingModal"))
);
const EditPaymentModal = Loadable(
  lazy(() => import("../pages/agent/modal/EditPaymentModal"))
);
const ApplicationModal = Loadable(
  lazy(() => import("../pages/agent/modal/ApplicationModal"))
);
const AddTermInfo = Loadable(
  lazy(() => import("../pages/agent/Listings/AddTermInfo/AddTermInfo"))
);

// ----------------- Setting pages ---------------------------------
const Setting = Loadable(lazy(() => import("../pages/setting/Setting")));

const Page404 = Loadable(lazy(() => import("../pages/Page404")));

//tenant
const Landing = Loadable(lazy(() => import("../pages/tenant/Landing")));
const TenantRegister = Loadable(
  lazy(() => import("../pages/tenant/OnBoarding/Register/Register"))
);
const TenantLogin = Loadable(
  lazy(() => import("../pages/tenant/OnBoarding/Login/Login"))
);
const VerifyOtp = Loadable(
  lazy(() => import("../pages/tenant/OnBoarding/Verify/Verify"))
);
const Details = Loadable(
  lazy(() => import("../pages/tenant/OnBoarding/Details/Details"))
);
const Current = Loadable(
  lazy(() => import("../pages/tenant/OnBoarding/currentLandlord/Current"))
);
const Document = Loadable(
  lazy(() => import("../pages/tenant/OnBoarding/Document/Document"))
);
const BankStatement = Loadable(
  lazy(() => import("../sections/tenantDashboard/BankStatement.js"))
);
const AddressHistory = Loadable(
  lazy(() => import("../pages/tenant/OnBoarding/AddressHistory/AddressHistory"))
);
// const AddLivingArrangement = Loadable(
//   lazy(() =>
//     import("../pages/tenant/OnBoarding/AddLivingArrangement/AddLivingArrangement")
//   )
// );
// const ConnectBank = Loadable(
//   lazy(() => import("../pages/tenant/OnBoarding/ConnectBank/ConnectBank"))
// );
const SignAgreement = Loadable(
  lazy(() => import("../pages/tenant/OnBoarding/SignAgreement/SignAgreement"))
);

// const Applications = Loadable(
//   lazy(() => import("../pages/tenant/Applications/Applications"))
// );

const ReviewApplication = Loadable(
  lazy(() => import("../pages/tenant/ReviewApplication/ReviewApplication"))
);

const RedirectingPage = Loadable(
  lazy(() => import("../utils/RedirectingPage"))
)

// const AddLivingArrangementForm = Loadable(
//   lazy(() =>
//     import("../pages/tenant/OnBoarding/AddLivingArrangement/AddLivingArrangement")
//   )
// );
const ApplicationPreferences = Loadable(
  lazy(() =>
    import("../pages/tenant/OnBoarding/ApplicationPreferences/ApplicationPreferences")
  )
);
const SearchPreference = Loadable(
  lazy(() =>
    import("../pages/tenant/OnBoarding/SearchPreference/SearchPreference")
  )
);

const RentalStatus = Loadable(
  lazy(() => import("../pages/tenant/OnBoarding/RentalStatus/RentalStatus"))
);

const NotificationSetting = Loadable(
  lazy(() =>
    import("../pages/tenant/OnBoarding/NotificationSetting/NotificationSetting")
  )
);
const Watchlist = Loadable(
  lazy(() => import("../pages/tenant/Dashboard/Watchlist"))
);
const ViewProperty = Loadable(
  lazy(() => import("../pages/tenant/Dashboard/ViewProperty"))
);
const BrowseProperties = Loadable(
  lazy(() => import("../pages/tenant/Dashboard/BrowseProperties"))
);

// const Page404 = Loadable(lazy(() => import("../pages/Page404")));

//
